import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import { useAuthStore } from "@/store/auth.store";

const app = createApp(App);
app.use(PrimeVue, { ripple: true });
// Tooltip目前被SpeedDial依賴
app.directive("tooltip", Tooltip);
app.use(ToastService);
app.use(ConfirmationService);
app.use(createPinia());

useAuthStore()
  .fetch()
  .then(() => {
    app.use(router);
    app.mount("#app");
  });
