
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import { computed, defineComponent, ref } from "vue";
import {
  CombinedTargetResponse,
  TriggerDirection,
  validateType,
} from "@evolutivelabs/quokka-common";
import { useCombinedTargetStore } from "@/store/combinedTarget.store";
import { useTargetStore } from "@/store/target.store";

export default defineComponent({
  components: {
    Button,
    InputText,
    Dialog,
    Dropdown,
    InputNumber,
    InputSwitch,
  },
  setup() {
    const showDialog = ref<boolean>(false);

    const dialogType = ref<"create" | "update">("update");

    const combinedTarget = ref<CombinedTargetResponse>(
      new CombinedTargetResponse()
    );

    const validator = computed<Record<string, boolean>>(() => ({
      title: combinedTarget.value.title.length > 0,
      sources: combinedTarget.value.sources.every((s) => s.id.length > 0),
    }));

    function open(t?: CombinedTargetResponse) {
      confirmed.value = false;
      if (t !== undefined) {
        dialogType.value = "update";
        const deepCopy = JSON.parse(JSON.stringify(t));
        if (validateType(CombinedTargetResponse, deepCopy)) {
          combinedTarget.value = deepCopy;
        }
      } else {
        dialogType.value = "create";
        combinedTarget.value = new CombinedTargetResponse();
        pushCandidateToSources();
      }
      showDialog.value = true;
    }

    function pushCandidateToSources() {
      combinedTarget.value.sources.push({ id: "", multiplier: 1 });
    }

    const combinedTargetStore = useCombinedTargetStore();

    const confirmed = ref(false);

    async function confirm() {
      confirmed.value = true;
      const validations: boolean[] = Object.values(validator.value);
      const dto = {
        title: combinedTarget.value.title,
        unit: combinedTarget.value.unit,
        goalTriggerDirection: combinedTarget.value.goalTriggerDirection,
        goalTriggerValue: combinedTarget.value.goalTriggerValue,
        enableGoalTrigger: combinedTarget.value.enableGoalTrigger,
        alertTriggerDirection: combinedTarget.value.alertTriggerDirection,
        alertTriggerValue: combinedTarget.value.alertTriggerValue,
        enableAlertTrigger: combinedTarget.value.enableAlertTrigger,
        sources: combinedTarget.value.sources,
      };
      if (!validations.every((x) => x)) {
        console.log("do nothing");
      } else if (dialogType.value === "update") {
        await combinedTargetStore.update(combinedTarget.value.id, dto);
        showDialog.value = false;
      } else if (dialogType.value === "create") {
        await combinedTargetStore.create(dto);
        showDialog.value = false;
      }
    }

    const confirmLabel = computed(() => {
      if (dialogType.value === "update") return "Save";
      return "Create";
    });

    async function remove() {
      await combinedTargetStore.remove(combinedTarget.value.id);
      showDialog.value = false;
    }

    const currencies = [
      { name: "-----", value: "" },
      { name: "NTD", value: "NT$" },
      { name: "USD", value: "$" },
      { name: "EUR", value: "€" },
      { name: "GBP", value: "£" },
    ];

    const goalDirection = computed(() => {
      if (
        combinedTarget.value.goalTriggerDirection === TriggerDirection.greater
      )
        return ">";
      return "<";
    });

    const changeGoalDirection = () => {
      combinedTarget.value.goalTriggerDirection =
        combinedTarget.value.goalTriggerDirection === TriggerDirection.greater
          ? TriggerDirection.less
          : TriggerDirection.greater;
    };

    const alertDirection = computed(() => {
      if (
        combinedTarget.value.alertTriggerDirection === TriggerDirection.greater
      )
        return ">";
      return "<";
    });

    const changeAlertDirection = () => {
      combinedTarget.value.alertTriggerDirection =
        combinedTarget.value.alertTriggerDirection === TriggerDirection.greater
          ? TriggerDirection.less
          : TriggerDirection.greater;
    };

    const targetList = computed(() => {
      return useTargetStore().targets.map((e) => {
        let disabled = false;
        combinedTarget.value.sources.forEach((s) => {
          if (e.id === s.id) disabled = true;
        });
        return { name: e.title, value: e.id, disabled };
      });
    });

    return {
      confirm,
      confirmed,
      confirmLabel,
      combinedTarget,
      open,
      showDialog,
      validator,
      remove,
      currencies,
      goalDirection,
      changeGoalDirection,
      alertDirection,
      changeAlertDirection,
      targetList,
      dialogType,
      pushCandidateToSources,
    };
  },
});
