import { defineStore } from "pinia";
import axios from "axios";
import {
  API_ROOT,
  ApiRoute,
  UserResponse,
  validateType,
} from "@evolutivelabs/quokka-common";
import { ref } from "vue";

const api = axios.create({
  baseURL: `/${API_ROOT}/${ApiRoute.User}`,
  timeout: 3000,
});

export const useUserStore = defineStore("user", () => {
  const user = ref<UserResponse>(new UserResponse());

  async function fetch() {
    const resp = await api.get("");
    if (validateType(UserResponse, resp.data)) {
      user.value = resp.data;
    }
  }

  return { fetch, user };
});
