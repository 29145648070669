import LogoDev from "@/assets/logo-dev.png";
import LogoStag from "@/assets/logo-stag.png";
import LogoProd from "@/assets/logo-prod.png";

export enum Mode {
  Development = "development",
  Staging = "staging",
  Production = "production",
}

interface Config {
  MODE: Mode;
  LOGO: string;
}

const devConfig: Config = {
  MODE: Mode.Development,
  LOGO: LogoDev,
};

const stagConfig: Config = {
  MODE: Mode.Staging,
  LOGO: LogoStag,
};

const prodConfig: Config = {
  MODE: Mode.Production,
  LOGO: LogoProd,
};

const env = process.env.VUE_APP_QUOKKA_MODE;
export const config = Object.freeze(
  env === Mode.Production
    ? prodConfig
    : env === Mode.Staging
    ? stagConfig
    : devConfig
);
