import { defineStore } from "pinia";
import axios from "axios";
import {
  API_ROOT,
  ApiRoute,
  CanvasResponse,
  CreateCanvasDto,
  RelocateCanvasDto,
  UpdateCanvasDto,
  validateType,
} from "@evolutivelabs/quokka-common";
import { ref } from "vue";

const api = axios.create({
  baseURL: `/${API_ROOT}/${ApiRoute.Canvas}`,
  timeout: 3000,
});

export const useCanvasStore = defineStore("canvas", () => {
  const canvases = ref<CanvasResponse[]>([]);

  async function fetch() {
    const resp = await api.get("");
    if (
      Array.isArray(resp.data) &&
      resp.data.every((d) => validateType(CanvasResponse, d))
    ) {
      canvases.value = resp.data;
    }
  }

  async function update(id: string, dto: UpdateCanvasDto) {
    const resp = await api.patch(id, dto);
    const canvas = resp.data;
    if (validateType(CanvasResponse, canvas)) {
      canvases.value = canvases.value.map((c) =>
        c.id === canvas.id ? canvas : c
      );
    }
  }

  async function create(dto: CreateCanvasDto): Promise<CanvasResponse> {
    const resp = await api.post("", dto);
    const canvas = resp.data;
    if (!validateType(CanvasResponse, canvas)) {
      throw new Error("CanvasResponse type error");
    }
    canvases.value.push(canvas);
    return canvas;
  }

  async function relocate(dto: RelocateCanvasDto[]) {
    await api.patch("", dto);
  }

  async function remove(id: string) {
    await api.delete(id);
    canvases.value = canvases.value.filter((c) => c.id !== id);
  }

  return { canvases, fetch, update, relocate, create, remove };
});
