
import Tag from "primevue/tag";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import SelectButton from "primevue/selectbutton";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import { computed, defineComponent, ref } from "vue";
import { TargetResponse, TriggerDirection } from "@evolutivelabs/quokka-common";
import { useTargetStore } from "@/store/target.store";

export default defineComponent({
  components: {
    Button,
    InputText,
    Dialog,
    Dropdown,
    Tag,
    SelectButton,
    InputNumber,
    InputSwitch,
  },
  setup() {
    const showDialog = ref<boolean>(false);

    const target = ref<TargetResponse>(new TargetResponse());

    const validator = computed<Record<string, boolean>>(() => ({
      title: target.value.title.length > 0,
    }));

    function open(t: TargetResponse) {
      target.value = { ...t };
      showDialog.value = true;
    }

    const targetStore = useTargetStore();

    async function confirm() {
      const validations: boolean[] = Object.values(validator.value);
      if (!validations.every((x) => x)) {
        console.log("do nothing");
      } else {
        await targetStore.update(target.value.id, {
          title: target.value.title,
          unit: target.value.unit,
          updateFrequencyInMinute: target.value.updateFrequencyInMinute,
          goalTriggerDirection: target.value.goalTriggerDirection,
          goalTriggerValue: target.value.goalTriggerValue,
          enableGoalTrigger: target.value.enableGoalTrigger,
          alertTriggerDirection: target.value.alertTriggerDirection,
          alertTriggerValue: target.value.alertTriggerValue,
          enableAlertTrigger: target.value.enableAlertTrigger,
        });
        showDialog.value = false;
      }
    }

    async function remove() {
      await targetStore.remove(target.value.id);
      showDialog.value = false;
    }

    const currencies = [
      { name: "-----", value: "" },
      { name: "NTD", value: "NT$" },
      { name: "USD", value: "$" },
      { name: "EUR", value: "€" },
      { name: "GBP", value: "£" },
    ];

    const frequencies = [
      { name: "Every 5 Mins", value: 5 },
      { name: "Every 1 Hour", value: 60 },
      { name: "Every 24 Hours", value: 1440 },
    ];

    const changeGoalDirection = () => {
      target.value.goalTriggerDirection =
        target.value.goalTriggerDirection === TriggerDirection.greater
          ? TriggerDirection.less
          : TriggerDirection.greater;
    };

    const alertDirection = computed(() => {
      if (target.value.alertTriggerDirection === TriggerDirection.greater)
        return ">";
      return "<";
    });

    const changeAlertDirection = () => {
      target.value.alertTriggerDirection =
        target.value.alertTriggerDirection === TriggerDirection.greater
          ? TriggerDirection.less
          : TriggerDirection.greater;
    };

    return {
      confirm,
      target,
      open,
      showDialog,
      validator,
      remove,
      currencies,
      frequencies,
      changeGoalDirection,
      alertDirection,
      changeAlertDirection,
    };
  },
});
