import { defineStore } from "pinia";
import axios from "axios";
import {
  API_ROOT,
  ApiRoute,
  TargetDodResponse,
  TargetHistoryResponse,
  TargetResponse,
  UpdateTargetDto,
  validateType,
} from "@evolutivelabs/quokka-common";
import { ref } from "vue";
import { IsArray, IsString, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

const api = axios.create({
  baseURL: `/${API_ROOT}/${ApiRoute.Target}`,
  timeout: 3000,
});

export const useTargetStore = defineStore("target", () => {
  const targets = ref<TargetResponse[]>([]);
  const targetDods = ref<TargetDodResponse[]>([]);

  async function fetch() {
    const resp = await api.get("");
    if (
      Array.isArray(resp.data) &&
      resp.data.every((d) => validateType(TargetResponse, d))
    ) {
      targets.value = resp.data;
    }
    await updateTargetDods();
  }

  async function update(id: string, dto: UpdateTargetDto) {
    const resp = await api.patch(id, dto);
    const target = resp.data;
    if (validateType(TargetResponse, target)) {
      targets.value = targets.value.map((t) =>
        t.id === target.id ? target : t
      );
    }
  }

  async function remove(id: string) {
    await api.delete(id);
    targets.value = targets.value.filter((t) => t.id !== id);
  }

  async function updateTargetDods() {
    const dodStorageKey = "dod";
    const today = new Date();
    const cacheKey = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
    const cacheData = JSON.parse(
      window.localStorage.getItem(dodStorageKey) ?? "{}"
    );
    if (validateType(CacheDod, cacheData)) {
      if (cacheData.key === cacheKey) {
        targetDods.value = cacheData.value;
        return;
      }
    }

    const pastHours = today.getHours();
    const resp = await api.get(`dod?pastHours=${pastHours}`);
    if (
      Array.isArray(resp.data) &&
      resp.data.every((d) => validateType(TargetDodResponse, d))
    ) {
      const newCache = new CacheDod(cacheKey, resp.data);
      window.localStorage.setItem(dodStorageKey, JSON.stringify(newCache));
      targetDods.value = resp.data;
    }
  }

  async function fetchHistory(id: string) {
    const resp = await api.get(`${id}/history`);
    if (
      Array.isArray(resp.data) &&
      resp.data.every((d) => validateType(TargetHistoryResponse, d))
    ) {
      return resp.data;
    }
  }

  return { targets, targetDods, fetch, update, remove, fetchHistory };
});

class CacheDod {
  constructor(dateString: string, targetDods: TargetDodResponse[]) {
    this.key = dateString;
    this.value = targetDods;
  }
  @IsString()
  key: string;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => TargetDodResponse)
  value: TargetDodResponse[];
}
