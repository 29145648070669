import { defineStore } from "pinia";
import axios from "axios";
import {
  API_ROOT,
  ApiRoute,
  LoginInfo,
  validateType,
} from "@evolutivelabs/quokka-common";
import { ref } from "vue";

const api = axios.create({
  baseURL: `/${API_ROOT}/${ApiRoute.Auth}`,
  timeout: 3000,
});

export const useAuthStore = defineStore("auth", () => {
  const isLoggedIn = ref(false);
  const isRhinoshield = ref(false);

  async function fetch() {
    const resp = await api.get("/loginInfo");

    if (validateType(LoginInfo, resp.data)) {
      isLoggedIn.value = resp.data.isLogin;
      isRhinoshield.value = resp.data.isRhinoshield;
    }
  }

  async function logout() {
    await api.get("/logout");
  }

  return { fetch, isLoggedIn, isRhinoshield, logout };
});
