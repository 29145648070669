import { defineStore } from "pinia";
import {
  API_ROOT,
  ApiRoute,
  BoxResponse,
  CreateDeviceTokenResponse,
  DeviceResponse,
  TmdResponse,
  UpdateBoxDto,
  validateType,
} from "@evolutivelabs/quokka-common";
import { ref } from "vue";
import axios from "axios";

const api = axios.create({
  baseURL: `/${API_ROOT}/${ApiRoute.Device}`,
  timeout: 3000,
});

const boxApi = axios.create({
  baseURL: `/${API_ROOT}/${ApiRoute.Box}`,
  timeout: 3000,
});

export const useDeviceStore = defineStore("device", () => {
  const tmds = ref<TmdResponse[]>([]);
  const boxes = ref<BoxResponse[]>([]);

  async function getCreateTokenResponse(): Promise<CreateDeviceTokenResponse> {
    const resp = await api.get("/token");
    if (validateType(CreateDeviceTokenResponse, resp.data)) {
      return resp.data;
    } else {
      throw new Error("TypeError: CreateDeviceTokenResponse");
    }
  }

  async function fetch() {
    const resp = await api.get("");
    if (validateType(DeviceResponse, resp.data)) {
      tmds.value = resp.data.tmds;
      boxes.value = resp.data.boxes;
    } else {
      throw new Error("TypeError: DeviceResponse");
    }
  }

  async function updateBox(id: string, dto: UpdateBoxDto) {
    const resp = await boxApi.patch(id, dto);
    const updatedBox = resp.data;
    if (validateType(updatedBox, BoxResponse)) {
      boxes.value = boxes.value.map((box) => {
        return box.id === updatedBox.id ? updatedBox : box;
      });
    }
  }

  return { getCreateTokenResponse, tmds, boxes, fetch, updateBox };
});
