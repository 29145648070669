import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Targets from "../views/Targets.vue";
import TargetChart from "../views/TargetChart.vue";
import Devices from "../views/Devices.vue";
import NotFound from "../views/NotFound.vue";
import Welcome from "../views/Welcome.vue";
import Privacy from "../views/Privacy.vue";
import { Route } from "@evolutivelabs/quokka-common";
import { useAuthStore } from "@/store/auth.store";

declare module "vue-router" {
  interface RouteMeta {
    onlyRhinoshield?: boolean;
    requireAuth?: boolean;
    requireNonAuth?: boolean;
  }
}

export enum RouteName {
  Home = "Home",
  Login = "Login",
  Dashboard = "Dashboard",
  Targets = "Targets",
  TargetChart = "TargetChart",
  Devices = "Devices",
  NotFound = "NotFound",
  Welcome = "Welcome",
  Privacy = "Privacy",
}

const routes: Array<RouteRecordRaw> = [
  {
    path: Route.Home,
    name: RouteName.Home,
    component: Home,
  },
  { path: "/privacy", name: RouteName.Privacy, component: Privacy },
  {
    path: Route.Login,
    name: RouteName.Login,
    component: Login,
    meta: { requireNonAuth: true },
  },
  {
    path: Route.Dashboard,
    name: RouteName.Dashboard,
    component: Dashboard,
    meta: { requireAuth: true },
  },
  {
    path: Route.Targets,
    name: RouteName.Targets,
    component: Targets,
    meta: { requireAuth: true },
  },
  {
    path: `${Route.Targets}/:id`,
    name: RouteName.TargetChart,
    component: TargetChart,
    meta: { requireAuth: true },
  },
  {
    path: Route.Devices,
    name: RouteName.Devices,
    component: Devices,
    meta: { requireAuth: true, onlyRhinoshield: true },
  },
  {
    path: Route.Welcome,
    name: RouteName.Welcome,
    component: Welcome,
    meta: { requireAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: RouteName.NotFound,
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (
    (to.meta.requireNonAuth && authStore.isLoggedIn) ||
    (to.meta.onlyRhinoshield && !authStore.isRhinoshield)
  ) {
    next(Route.Home);
  } else if (to.meta.requireAuth && !authStore.isLoggedIn) {
    next(Route.Login);
  } else {
    next();
  }
});

export default router;
