import { defineStore } from "pinia";
import axios from "axios";
import {
  API_ROOT,
  ApiRoute,
  CombinedTargetResponse,
  UpdateCombinedTargetDto,
  validateType,
  CreateCombinedTargetDto,
} from "@evolutivelabs/quokka-common";
import { ref } from "vue";

const api = axios.create({
  baseURL: `/${API_ROOT}/${ApiRoute.CombinedTarget}`,
  timeout: 3000,
});

export const useCombinedTargetStore = defineStore("combinedTarget", () => {
  const combinedTargets = ref<CombinedTargetResponse[]>([]);

  async function fetch() {
    const resp = await api.get("");
    if (
      Array.isArray(resp.data) &&
      resp.data.every((d) => validateType(CombinedTargetResponse, d))
    ) {
      combinedTargets.value = resp.data;
    }
  }

  async function create(dto: CreateCombinedTargetDto) {
    const resp = await api.post("", dto);
    const combinedTarget = resp.data;
    if (validateType(CombinedTargetResponse, combinedTarget)) {
      combinedTargets.value.push(combinedTarget);
    }
  }

  async function update(id: string, dto: UpdateCombinedTargetDto) {
    const resp = await api.patch(id, dto);
    const combinedTarget = resp.data;
    if (validateType(CombinedTargetResponse, combinedTarget)) {
      combinedTargets.value = combinedTargets.value.map((t) =>
        t.id === combinedTarget.id ? combinedTarget : t
      );
    }
  }

  async function remove(id: string) {
    await api.delete(id);
    combinedTargets.value = combinedTargets.value.filter((t) => t.id !== id);
  }

  return { combinedTargets, fetch, create, update, remove };
});
