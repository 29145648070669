<template>
  <Dialog
    @hide="resetModal"
    header="Quokka Box Information"
    v-model:visible="showDialog"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <div
      class="
        flex
        justify-content-between
        align-items-center
        justify-content-center
        border-round
        py-2
        border-bottom-1 border-black-alpha-10
      "
    >
      <div>Unique box device id :</div>
      <div class="surface-500 text-white">{{ modalBox.boxId }}</div>
    </div>

    <div
      class="
        flex
        justify-content-between
        align-items-center
        justify-content-center
        border-round
        py-2
        border-bottom-1 border-black-alpha-10
      "
    >
      <div>Box name :</div>
      <InputText type="text" v-model="modalBox.name" placeholder="Box Name" />
    </div>

    <div
      class="
        flex
        justify-content-between
        align-items-center
        justify-content-center
        border-round
      "
    >
      <span>Source :</span
      ><Dropdown
        v-model="selectedTarget"
        @change="editModal"
        :options="unitedItems"
        optionLabel="title"
        placeholder="Select a Target"
      />
    </div>
    <div v-if="selectedTarget?.enableGoalTrigger" class="flex">
      <i class="pi pi-flag-fill pr-2"> </i>
      The goal is
      <span class="bg-primary px-1 mx-1">
        {{ selectedTarget?.goalTriggerDirection }}
      </span>
      than
      <span class="bg-primary px-1 mx-1">
        {{ selectedTarget?.goalTriggerValue }}</span
      >
    </div>
    <div v-else-if="selectedTarget?.id">
      <i class="pi pi-exclamation-triangle pr-2"></i>This source doesn't set
      goal.
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        @click="showDialog = false"
        class="p-button-text"
      />
      <Button label="Save" icon="pi pi-check" @click="saveModal" autofocus />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import {
  BoxResponse,
  CombinedTargetResponse,
  TargetResponse,
} from "@evolutivelabs/quokka-common";
import { useTargetStore } from "@/store/target.store";
import { useCombinedTargetStore } from "@/store/combinedTarget.store";
import { storeToRefs } from "pinia";
import { useDeviceStore } from "@/store/device.store";

export default defineComponent({
  components: {
    Button,
    InputText,
    Dialog,
    Dropdown,
  },
  setup() {
    const deviceStore = useDeviceStore();
    const targetStore = useTargetStore();
    const combinedTargetStore = useCombinedTargetStore();
    const { targets } = storeToRefs(targetStore);
    const { combinedTargets } = storeToRefs(combinedTargetStore);
    const filterInput = ref("");
    const showDialog = ref(false);
    const box = ref<BoxResponse>(new BoxResponse());
    const unitedItems = computed(() => {
      return [
        { title: "-- Disable --" },
        ...targets.value,
        ...combinedTargets.value,
      ];
    });

    class ModalBox {
      name = "";
      boxId = "";
      targetIds: string[] = [];
      combinedTargetIds: string[] = [];
    }
    const modalBox = ref<ModalBox>(new ModalBox());
    const selectedTarget = ref<TargetResponse | CombinedTargetResponse>();
    const resetModal = () => {
      modalBox.value.name = box.value.name;
      modalBox.value.targetIds = box.value.targets.map((x) => x.id);
      modalBox.value.combinedTargetIds = box.value.combinedTargets.map(
        (x) => x.id
      );

      if (box.value.targets.length > 0) {
        selectedTarget.value = box.value.targets[0];
      } else if (box.value.combinedTargets.length > 0) {
        selectedTarget.value = box.value.combinedTargets[0];
      }
    };
    const editModal = () => {
      const selectId = selectedTarget.value?.id;
      modalBox.value.targetIds = [];
      modalBox.value.combinedTargetIds = [];
      if (selectId) {
        if (box.value.combinedTargets.find((x) => x.id === selectId)) {
          modalBox.value.combinedTargetIds.push(selectId);
        } else {
          modalBox.value.targetIds.push(selectId);
        }
      }
    };

    const saveModal = () => {
      deviceStore.updateBox(modalBox.value.boxId, {
        name: modalBox.value.name,
        targetIds: modalBox.value.targetIds,
        combinedTargetIds: modalBox.value.combinedTargetIds,
      });
      showDialog.value = false;
    };

    function open(b: BoxResponse) {
      box.value = b;
      modalBox.value.boxId = b.id;
      resetModal();
      showDialog.value = true;
    }

    return {
      showDialog,
      filterInput,
      modalBox,
      editModal,
      selectedTarget,
      resetModal,
      saveModal,
      open,
      unitedItems,
    };
  },
});
</script>

<style scoped lang="stylus">
.card-container
  display flex
  flex-wrap wrap
.p-card
  width 350px
</style>
